import phpApi from "@/utils/phpApi";
import httpUtil from "@/utils/httpUtil";

/**模型zip上传判断 */
export const offLineQueryNo =  params => phpApi.post1("/offLine/queryNo", params);

/**模型zip上传 */
export const offLineZip =  params => phpApi.uploadZip("/api/took/card/upload/zip", params);

/**获取iframe截图 */
export const coverGet =  params => phpApi.getIframeImg("/offLine/cover/get", params);


/**上传模型 */
export const blendAdd =  params => httpUtil.post("/api/crmPc/blend/add", params);

/**更新模型 */
export const blendUpdate =  params => httpUtil.post("/api/crmPc/blend/update", params);

/**模型列表 */
export const blendQueryPage =  params => httpUtil.post("/api/crmPc/blend/queryPage", params);

/**访问状态 */
export const blendSwitchExplore =  params => httpUtil.post("/api/crmPc/blend/switchExplore", params);

/**删除模型 */
export const blendDeleteById =  params => httpUtil.post("/api/crmPc/blend/deleteById", params);

/**上传图片 */
export const uploadImg =  params => phpApi.uploadImg("/file/uploadObjectOSS", params,);

/**添加模型分类 */
export const typeAdd =  params => httpUtil.post("/api/crmPc/blend/type/add", params);

/**分页查询模型分类 */
export const typeQueryPage =  params => httpUtil.post("/api/crmPc/blend/type/queryPage", params);

/**更新标签分类 */
export const typeUpdate =  params => httpUtil.post("/api/crmPc/blend/type/update", params);

/**删除模型分类 */
export const typeDeleteById =  params => httpUtil.post("/api/crmPc/blend/type/deleteById", params);